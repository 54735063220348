import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import authAxios from "../../components/authAxios";
import { useNavigate } from "react-router-dom";

import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { VisibilityOutlined } from "@mui/icons-material";
// import { BorderColorIcon } from "@mui/icons-material";
// import { BorderColorSharpIcon } from "@mui/icons-material";

import BorderColorSharpIcon from '@mui/icons-material/BorderColor';

import { Tabs } from "antd";
import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import EllipsisMenu from "../../components/EllipsisMenu";
import DashboardHeader from "../../components/DashboardHeader";
import CustomSearchBar from "../../components/CustomSearchBar";
import PaymentEdit from "./PaymentEdit";

// import './Crm.css'


const Payments = () => {
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [status, setStatus] = useState("");
    const [searchText, setSearchText] = useState('');
    const [property, setProperty] = useState("");


    const { TabPane } = Tabs;
    const { data: clientProfile, refetch } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data
        },
    });

    // const { data: customers, ...rest  } = useQuery({

    const { data: payments  } = useQuery({
        queryKey: ['payments', status, page],
        queryFn: async () => {
        //   const response = await authAxios.get(`/payments/payments/?status=${status}&property=${property}&page=${page}&search=${searchText}`);
          const response = await authAxios.get(`/payments/payments/?status=${status}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

 


    const onChange = (key) => {
        setStatus(key)
        if (key==="000"){
            setStatus("")
        }
        if (key==="020"){
            setProperty("")
        }

      };

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    

    return (
            <div className="dashboard-main">                
               
                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">

                        <DashboardHeader dashboardTitle="Payments" dashboardPreTitle="Accounting" removeBorder={true} />

                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>


                    <Tabs defaultActiveKey="All" onChange={onChange}>
                        <TabPane tab="All" key="000">
                            <FilterPayments 
                                // status="" 
                                status={status} 
                                clientProfile={clientProfile} 
                                payments={payments}      
                                // property={property}                         
                                // setProperty={setProperty}
                            />
                        </TabPane>
                        <TabPane tab="Posted" key="010">
                            <FilterPayments 
                                status={status}
                                clientProfile={clientProfile}
                                payments={payments}
                                // property={property}
                                // setProperty={setProperty}

                            />                 
                        </TabPane>

                        <TabPane tab="Failed" key="040">
                            <FilterPayments 
                                // status="020" 
                                status={status}
                                clientProfile={clientProfile} 
                                payments={payments}
                            />
                        </TabPane >
                    </Tabs>


                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />
                </section>
            </div>

    )
}
export default Payments


const FilterPayments = ({ status, payments, clientProfile }) => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [payment, setPayment] = useState("")

    const menuItems = (payment) => {
        const baseItems = [
            {
                label: 'View',
                icon: <VisibilityOutlined />,
                onClick: () => {
                    navigate(`/dashboard/payment/${payment.id}`)
                }
            },
        ];
    
        if (payment.status === '040') {
            baseItems.push({
                label: 'Edit',
                icon: <BorderColorSharpIcon />,
                onClick: () => {
                    setPayment(payment)
                    setIsModalOpen(true)
                }
            });
        }
    
        return baseItems;
    };

    const handleChipClick = (e) => {
        // if (e.id){
        //     setProperty(e.id)
        // }
        // else{
        //     setProperty("")
        // }
      };



    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

  
    return (
    <div>
        {clientProfile &&
            <div className="filter-customers">

                <table className="styled-table">
                <thead>
                    <tr >
                        <th>ID</th>
                        <th>Paid By</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Channel</th>
                        <th className="cell-align-right">Amount</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                {payments && payments.map(payment => (

                    <tbody key={payment.id}>
                        <tr>
                            <td>{payment.id}</td>
                            <td>{payment.customer_name}</td>
                            <td>{payment.reference}</td>
                            <td>{convertDateTime(payment.date)}</td>
                            <td>{payment.type_name}</td>
                            {clientProfile && clientProfile.currency_code? 
                                <td className="cell-align-right">{Number(payment.amount).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(payment.amount).toLocaleString('en-UK')}</td>
                            }
                            <td><Pillchip label={payment.status_name} /></td>

                            <td className='styled-table-menu'>
                                <EllipsisMenu items={menuItems(payment)} />
                            </td>
                        </tr>
                    </tbody>
                ))}


                </table>

                <PaymentEdit
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    payment={payment}
                />
            </div>

        }

    </div>
    );
  };


