import { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

import { Modal } from 'antd';


import NumberFormat from "react-number-format";
// import {UserContext} from "../../App"
import UserAlerts from "../../components/UserAlerts";
// import DashboardHeader from "../../components/DashboardHeader";

// import './Rentals.css'


function PaymentEdit({payment, isModalOpen, setIsModalOpen}) {
    const autocompleteRef = useRef(null);

    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const errorRef = useRef(null);



    const queryClient = useQueryClient();

    // const page_size = useContext(UserContext).page_size;
    // const user = localStorage.getItem('userID');
    // const client = localStorage.getItem('clientID');


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: leases, isLoading } = useQuery({
        queryKey: ['leases'],
        queryFn: async () => {
            // const response = await authAxios.get("/rentals/leases");
            const response = await authAxios.get("/rentals/lease-list");
            return response.data
        },
    });


    // const navigate = useNavigate();
    // const [customer, setCustomer] = useState('');
    const [customerReference, setCustomerReference] = useState('');

    const mutation = useMutation({
        mutationFn: (paymentData) => {
            return authAxios.put(`payments/payments/${payment.id}/`, paymentData);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['payments', 'payment']);
            queryClient.refetchQueries(['payments']);
            queryClient.refetchQueries(['payment']);
            setIsModalOpen(false)
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (customerReference ) {

            const type = payment.type
            const customer_reference = customerReference
            const paymentData = {type, customer_reference };

            console.log(paymentData)
            mutation.mutate(paymentData)
            
        } 
    
    }


    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    useEffect(() => {
        if (isModalOpen && autocompleteRef.current) {
          // Focus the Autocomplete input when the modal opens
          autocompleteRef.current.focus();
        }
      }, [isModalOpen]);

    return(
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Edit Payment" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            defaultValue = {payment.reference}
                            InputProps={{ readOnly: true }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                        />

                        <TextField className="custom-input"
                            fullWidth
                            label="Paid By" 
                            variant="outlined" 
                            defaultValue = {payment.customer_name}
                            InputProps={{ readOnly: true }}
                        />
                           <NumberFormat 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            defaultValue = {payment.amount}
                            InputProps={{ readOnly: true }}
                        />  
                        <Autocomplete
                            ref={autocompleteRef}
                            onChange={(event, newValue) => {
                                // setLease(newValue.id);
                                setCustomerReference(newValue.customer_code);
                                console.log("VALUE", newValue)
                                }}
                            options={leases}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Customer"  /> }
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    )

}

export default PaymentEdit;
